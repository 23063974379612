<template>
  <div>
    <div
      :style="`width: ${width}px;`"
    >
      <canvas
        :ref="chartId"
        :width="width"
        :height="height"
      />
    </div>
  </div>
</template>

<script>
  import Chart from 'chart.js/auto';
  import annotationPlugin from 'chartjs-plugin-annotation';

  export default {
    props: {

      chartId: {
        type: String,
        required: true,
      },

      chartData: {
        type: Object,
        required: true,
      },

      width: {
        type: [Number, String],
        default: () => 'auto',
      },

      height: {
        type: [Number, String],
        default: () => 'auto',
      },

      scrollable: {
        type: Boolean,
        default: false,
      },

      warningThreshold: {
        type: [Number],
        default: 100,
      },

      dangerThreshold: {
        type: [Number],
        default: 200,
      }
    },

    /**
     * When ChartJS instance (with annotation plugin enabled) is set to
     * component data, the instance becomes observable. That observable
     * "collides with the proxies ChartJS uses internally", erroring out
     * on "maximum call stack size exceeded":
     * https://github.com/chartjs/chartjs-plugin-annotation/issues/452
     *
     * To track the reference to the ChartJS instance, we can save it to
     * a custom option (named "chart" in this case):
     * https://vuejs.org/v2/api/#vm-options
     */
    chart: null,

    mounted() {
      this.initChart();
    },

    beforeDestroy() {
      this.destroyChart();
    },

    methods: {
      destroyChart() {
        if (!this.$options.chart) return;
        this.$options.chart.destroy();
        this.$options.chart = null;
      },

      async initChart() {
        this.destroyChart();

        const annotations = {
          warning: {
            type: 'box',
            backgroundColor: 'rgba(250, 209, 3, 0.51)',
            yMax: -this.warningThreshold,
            yMin: -this.dangerThreshold,
          },
          danger: {
            type: 'box',
            backgroundColor: 'rgba(255, 99, 132, 0.25)',
            yMax: -this.dangerThreshold,
          },
          optimal: {
            type: 'line',
            yMin: 0,
            yMax: 0,
            mode: 'horizontal',
            borderColor: 'green',
            borderWidth: 3,
          },
          good: {
            type: 'box',
            backgroundColor: 'rgba(121, 250, 2, 0.28)',
            yMin: -this.warningThreshold,
          },
        };

        if (this.chartData == null || this.chartData.length <= 0) return;

        Chart.register(annotationPlugin);

        const chart = new Chart(this.$refs[this.chartId], { // eslint-disable-line
          type: 'line',
          data: this.chartData,
          options: {
            layout: {
              padding: {
                bottom: 12,
              },
            },
            legend: false,
            plugins: {
              annotation: {
                annotations,
              },
            },
            elements: {
              point: {
                radius: 0,
              },
            },
            scales: {
              y: {
                suggestedMin: -200,
                suggestedMax: 200,
                beginAtZero: true,
                ticks: {
                  stepSize: 100,
                },
              },
            },
          },
        });

        this.$options.chart = chart;
      },
    },
  };
</script>
