<template>
  <div>
    <div
      :style="`width: ${width}px;`"
    >
      <canvas
        :ref="chartId"
        :width="width"
        :height="height"
      />
    </div>
  </div>
</template>

<script>
  import Chart from 'chart.js/auto';

  export default {
    props: {

      chartId: {
        type: String,
        required: true,
      },

      chartData: {
        type: Object,
        required: true,
      },

      chartType: {
        type: String,
        default: 'bar',
      },

      yAxisTickCallback: {
        type: Function,
        default: v => v,
      },

      tooltipCallback: {
        type: Function,
        default: ({ yLabel }) => yLabel,
      },

      width: {
        type: [Number, String],
        default: () => 'auto',
      },

      height: {
        type: [Number, String],
        default: () => 'auto',
      },

      scrollable: {
        type: Boolean,
        default: false,
      },
    },

    /**
     * When ChartJS instance (with annotation plugin enabled) is set to
     * component data, the instance becomes observable. That observable
     * "collides with the proxies ChartJS uses internally", erroring out
     * on "maximum call stack size exceeded":
     * https://github.com/chartjs/chartjs-plugin-annotation/issues/452
     *
     * To track the reference to the ChartJS instance, we can save it to
     * a custom option (named "chart" in this case):
     * https://vuejs.org/v2/api/#vm-options
     */
    chart: null,

    mounted() {
      this.initChart();
    },

    beforeDestroy() {
      this.destroyChart();
    },

    methods: {
      destroyChart() {
        if (!this.$options.chart) return;
        this.$options.chart.destroy();
        this.$options.chart = null;
      },

      async initChart() {
        this.destroyChart();

        if (this.chartData.length <= 0) return;

        const chart = new Chart(this.$refs[this.chartId], {
          type: this.chartType,
          data: this.chartData,
          options: {
            layout: {
              padding: {
                bottom: 12,
              },
            },
            legend: false,
            plugins: {
              annotation: false,
            },
            elements: {
              point: {
                radius: 0,
              },
            },
            tooltips: {
              callbacks: {
                label: this.tooltipCallback,
              },
            },
            scales: {
              x: {
                stacked: true,
              },
              y: {
                stacked: true,
              },
            },

          },
        });

        this.$options.chart = chart;
      },
    },
  };
</script>
