<template>
  <div
    class="monitored-projects"
  >
    <v-subheader>
      <span
        v-translate
        translate-context="MCustomerSuccessPage title"
      >
        Add projects to dashboard
      </span>
    </v-subheader>

    <v-select
      :items="unmonitoredProjects"
      item-text="pid"
      item-value="id"
      filled
      dense
      hide-details
      :menu-props="{ offsetY: true }"
      @input="addMonitoredProject"
    />

    <v-card
      tile
    >
      <v-simple-table
        dense
        style="border-radius: 0;"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th
                v-for="(header, i) in headers"
                :key="i"
                class="text-left"
              >
                <div
                  class="text-overflow-ellipsis"
                >
                  {{ header.title }}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="project in monitoredProjects"
              :key="project.id"
            >
              <!-- project / company -->
              <td>
                <div>
                  <div
                    class="text-overflow-ellipsis"
                    style=""
                  >
                    {{ project.pid }}
                  </div>

                  <div
                    class="caption-xs text--secondary"
                  >
                    {{ company(project).name }}
                  </div>
                </div>
              </td>


              <!-- actions over time -->
              <td>
                <m-task-breakdown-chart
                  v-if="projectSuccessMetrics(project.id)"
                  :chart-id="`actions-${project.id}`"
                  :chart-data="taktRoleTaskCompletionCount(project.id)"
                  :width="400"
                  :height="200"
                />
              </td>

              <!-- progress over time -->
              <td>
                <m-plan-distance-chart
                  v-if="projectSuccessMetrics(project.id)"
                  :chart-id="`plan-execution-${project.id}`"
                  :chart-data="taktPlanDistanceData(project.id)"
                  :dangerThreshold="taktPlanDistanceDangerThreshold(project.id)"
                  :warningThreshold="taktPlanDistanceWarningThreshold(project.id)"
                  :width="400"
                  :height="200"
                />
              </td>

              <td>
                <v-menu
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                    >
                      <v-icon>
                        more_horiz
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      @click="removeMonitoredProject(project.id)"
                    >
                      <v-list-item-icon>
                        <v-icon>
                          close
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title
                          v-translate
                          translate-context="MCustomerSuccessPage remove project from dashboard"
                        >
                          Remove from dashboard
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import MTaskBreakdownChart from '@/components/charts/MTaskBreakdownChart';
  import MPlanDistanceChart from '../components/charts/MPlanDistanceChart.vue';

  export default {
    components: {
      MTaskBreakdownChart,
      MPlanDistanceChart,
    },

    data: () => ({
      roleColor: {
        manager: 'rgba(255, 48, 48, 0.75)',
        worker: 'rgba(55, 216, 60, 0.75)',
        other: 'rgba(29, 143, 229, 0.75)',
      },
      monitored_project_ids: [],
    }),

    computed: {
      ...mapGetters({
        companies: 'companies/companies',
        projects: 'projects/projects',
        projectSuccessMetrics: 'analytics/projectSuccessMetrics',
      }),

      headers() {
        return [
          {
            title: 'Worksite, Company',
          },
          {
            title: 'Actions (managers vs. rest)',
          },
          {
            title: 'Actions over time',
          },
          {
            title: 'Edit',
          },
        ];
      },

      monitoredProjects() {
        return this.projects.filter(project => this.monitored_project_ids.includes(project.id));
      },

      unmonitoredProjects() {
        return this.projects.filter(project => !this.monitored_project_ids.includes(project.id));
      },
    },

    watch: {
      projects() {
        this.monitoredProjects.forEach(project => this.loadProjectSuccessMetrics(project.id));
      },
    },

    mounted() {
      // We're using local storage for PoC. If we want to offer this feature to
      // users also, then we should make an api for "Watching" / "Bookmarking" / "starring"
      // projects
      this.monitored_project_ids = JSON.parse(window.localStorage.getItem('MONITORED_PROJECT_IDS'));
      if (this.monitored_project_ids === null) {
        this.monitored_project_ids = [];
        window.localStorage.setItem('MONITORED_PROJECT_IDS', JSON.stringify(this.monitored_project_ids));
      }
      this.monitoredProjects.forEach(project => this.loadProjectSuccessMetrics(project.id));
    },


    methods: {
      ...mapActions({
        loadProjectSuccessMetrics: 'analytics/loadProjectSuccessMetrics',
      }),

      addMonitoredProject(item) {
        this.monitored_project_ids.push(item);
        this.loadProjectSuccessMetrics(item);
        window.localStorage.setItem('MONITORED_PROJECT_IDS', JSON.stringify(this.monitored_project_ids));
      },

      removeMonitoredProject(item) {
        const index = this.monitored_project_ids.indexOf(item);
        if (index > -1) {
          this.monitored_project_ids.splice(index, 1);
        }
        window.localStorage.setItem('MONITORED_PROJECT_IDS', JSON.stringify(this.monitored_project_ids));
      },

      taktRoleTaskCompletionCount(projectId) {
        const metrics = this.projectSuccessMetrics(projectId);

        if (!metrics) {
          return {};
        }

        const competionMetrics = metrics.taktRoleTaskCompletionCount;
        const maxTact = Math.max(...Object.keys(competionMetrics).map(x => parseInt(x, 10)));

        const labels = Array.from({ length: maxTact }, (_, i) => i + 1);

        // Initialize datasets for graph for each role
        const datasets = Object.entries(this.roleColor).reduce((acc, [role, color]) => {
          acc[role] = {
            data: Array(labels.length).fill(0),
            backgroundColor: color,
            borderColor: color,
            label: role,
          };

          return acc;
        }, {});

        Object.entries(competionMetrics).forEach(([tact, tactMetric]) => {
          // Use index of label to ensure order.
          const index = labels.indexOf(parseInt(tact, 10));

          Object.entries(tactMetric).forEach(([role, count]) => {
            if (role in this.roleColor) {
              datasets[role].data[index] = count;
            } else {
              datasets.other.data[index] = +count;
            }
          });
        });

        // Remove the keys
        return {
          labels,
          datasets: Object.values(datasets),
        };
      },


      taktPlanDistanceData(projectId) {
        return {
          labels: Object.keys(this.projectSuccessMetrics(projectId).taktPlanDistance),
          datasets: [{
            data: Object.values(this.projectSuccessMetrics(projectId).taktPlanDistance).map(x => x/60),
            backgroundColor: 'error',
            borderColor: 'error',
            label: 'Distance to plan',
            fill: false,
            stepped: true,
            lineTension: 0,
          },
          ],
        };
      },

      projectTotalHours(projectId) {
        const cumulative_minutes = Object.values(this.projectSuccessMetrics(projectId).cumulativePlannedTaktMinutes)
        if (cumulative_minutes.length > 0) {
          return Math.max.apply(Math, cumulative_minutes) / 60
        }
        return null
      },

      taktPlanDistanceDangerThreshold(projectId) {
        // WARN Assumes there's no tasks with negative minutes (ie cumulative is ever increasing)
        // Danger is when a project is already 10% behind total amount of project hours
        return this.projectTotalHours(projectId) * 0.10
      },

      taktPlanDistanceWarningThreshold(projectId) {
        // Warning is when a project is already 5% behind total amount of project hours
        return this.projectTotalHours(projectId) * 0.05
      },

      taktVelocityData(companyId) {
        const metrics = this.projectSuccessMetrics(companyId);

        const planned = metrics.plannedTaktMinutes;
        const executed = metrics.completedTaktMinutes;
        const labels = Object.keys(planned);

        const data = Object.entries(planned).map(([takt, plan]) => {
          const pressure = -parseInt(plan, 10);
          const velocity = executed[takt] || 0;
          return pressure + velocity;
        });

        // Initialize datasets for graph for each role
        const datasets = [{
          data,
          backgroundColor: 'error',
          borderColor: 'error',
          fill: false,
          lineTension: 0,
        },
        ];

        // Remove the keys
        return {
          labels,
          datasets,
        };
      },

      company({ companyId }) {
        const i = this.companies.findIndex(c => c.id === companyId);
        if (i < 0) return {};
        return this.companies[i];
      },
    },
  };
</script>

<style lang="scss">
.monitored-projects {
  td {
    font-size: 0.75rem !important;
  }

  thead th {
    border-bottom: 4px solid rgba(0, 0, 0, 0.24) !important;
  }

  th, td {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
}
</style>
